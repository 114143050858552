// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$colors-blue: #446084;
$colors-white: #ffffff;
$colors-grey: #e5e5e5;
$colors-black: #161922;
$colors-light-blue: rgba(47, 139, 255, 0.1);
