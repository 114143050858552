// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Variables

// Bootstrap
@import "https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import "~select2/dist/css/select2.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
@import "toastr";
@import "~sweetalert2/src/sweetalert2.scss";

body {
    background: rgba($color: #000000, $alpha: 0.05);
}

.bg-blue {
    background: $colors-blue;
}
/* table */
/* table */
.error {
    color: red;
    font-size: 0.7rem;
    width: 100%;
}
.table_content {
    background: #eaf1fb;
    .content-body {
        .card {
            background-color: transparent;
            box-shadow: none;
            background-color: transparent;
            box-shadow: none;
        }
        .card-content {
            .card-body {
                padding: 0;
            }
        }
        .table_content {
            .dataTables_wrapper {
                padding-left: 0;
                padding-right: 0;
            }
            div.dataTables_wrapper {
                width: 100%;
                margin: 0 auto;
            }
        }
    }
    table.table {
        width: 100% !important;
    }
    table.dataTable {
        border-spacing: 0 10px;
    }
    .card {
        background: transparent;
        border: none;
    }
    & > div {
        overflow-x: auto;
    }
}
.table {
    thead {
        th {
            border: none;
        }
    }
    th {
        border: none;
    }
    td {
        border: none;
    }
    > tbody {
        > * {
            > * {
                background-color: #fff;
            }
        }
        > tr {
            > td {
                vertical-align: middle;
            }
        }
    }
}
div.dataTables_wrapper {
    div.dataTables_length {
        .form-control {
            background-color: rgba(255, 255, 255, 0.7);
            height: 40px !important;
            border: none;
            border-radius: 10px;
        }
    }
    div.dataTables_filter {
        input {
            background-color: rgba(255, 255, 255, 0.7);
            height: 40px !important;
            border: none;
            border-radius: 10px;
            width: 300px !important;
        }
    }
}

tbody {
    tr {
        td {
            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}
td {
    .badge {
        padding: 10px 20px;
    }
    .badge.badge-success {
        color: #15a373;
        background-color: #dcf8ef;
        border-radius: 10px;
    }
    .badge.badge-danger {
        color: #d93127;
        background-color: #ffeae9;
        border-radius: 10px;
    }
    .badge.badge-info {
        color: #3682e0;
        background-color: #eaf1fb;
        border-radius: 10px;
    }
    .badge.badge-warning {
        color: #ffb12a;
        background-color: #fff3dd;
        border-radius: 10px;
    }
    .btn-social-icon.btn-success {
        color: #15a373;
        background-color: #dcf8ef !important;
        border-color: #dcf8ef !important;
    }
    .btn-social-icon.btn-warning {
        color: #ffb12a;
        background-color: #fff3dd !important;
        border-color: #fff3dd !important;
    }
    .btn-social-icon.btn-default {
        color: #3682e0;
        background-color: #eaf1fb !important;
        border-color: #eaf1fb !important;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.orders {
    .badge {
        padding: 10px 20px;
    }
    .badge.badge-success {
        color: #15a373;
        background-color: #dcf8ef;
        border-radius: 10px;
    }
    .badge.badge-danger {
        color: #d93127;
        background-color: #ffeae9;
        border-radius: 10px;
    }
    .badge.badge-info {
        color: #3682e0;
        background-color: #eaf1fb;
        border-radius: 10px;
    }
    .badge.badge-warning {
        color: #ffb12a;
        background-color: #fff3dd;
        border-radius: 10px;
    }
}
.manage-menu {
    .btn-social-icon.btn-success {
        color: #15a373;
        background-color: #dcf8ef !important;
        border-color: #dcf8ef !important;
    }
    .btn-danger {
        border-color: #ffeae9 !important;
    }
}
.dataTables_paginate {
    .page-item.disabled {
        .page-link {
            color: #b4c1d3;
            border-color: transparent;
        }
    }
    .page-item {
        .page-link {
            color: #000;
            padding: 10px 15px;
            margin-right: 3px;
        }
    }
    .page-item.active {
        .page-link {
            background-color: #ffb12a;
            border-color: #ffb12a;
            color: #000;
        }
    }
    .page-link {
        &:hover {
            background-color: #ffb12a;
            border-color: #ffb12a;
        }
    }
}
.page-link {
    color: #b4c1d3;
    border-color: transparent;
    color: #000;
    padding: 10px 15px;
    margin-right: 3px;
}
.dataTables_wrapper {
    padding-left: 0;
    padding-right: 0;
}
.dataTables_length {
    select.form-control.form-control-sm {
        margin: 0 8px;
    }
}
th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
table.dataTable {
    width: 100%;
}

.card {
    border: none;

    .card-header {
        border-bottom: none;
        background: $colors-blue;

        .text-primary {
            color: $colors-white !important;
        }
    }
}

.team-container {
    transition: 0.5s ease-in-out;
}

.animate-hide {
    transform: translateX(-500px);
    opacity: 0;
    transition: 0.2s ease-in-out;

    &.animate {
        transform: translateX(0);
        opacity: 1;
    }
}

span.error {
    display: block;
}

.ck-editor__editable {
    min-height: 200px !important;
}

.card-inner {
    .card-header {
        background: rgba($color: #000000, $alpha: 0.05);

        .text-primary {
            color: #000000 !important;
        }
    }

    .card-body {
        background: #fff;
    }
}

.domain-text {
    position: absolute;
    top: 23px;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.05);
    width: 110px;
    height: 37px;
    border-radius: 0.25rem;
}
